import React from 'react'
import { FormattedMessage } from 'react-intl'
import LocalizedLink from '../components/LocalizedLink'

const Form = props => (
  <div className="form">
    <form
      name="contact"
      method="post"
      action="/success"
      data-netlify="true"
      data-netlify-honeypot="bot-field"
    >
      <input type="hidden" name="bot-field" />
      <div className="field half first">
        <label htmlFor="name" />
        <input type="text" name="name" id="name" placeholder="Όνομα" />
      </div>
      <div className="field half">
        <label htmlFor="email" />
        <input type="text" name="email" id="email" placeholder="Email" />
      </div>
      <div className="field">
        <label htmlFor="message" />
        <textarea name="message" id="message" rows="6" placeholder="Μήνυμα" />
      </div>
      <input type="submit" value="Αποστολη μηνυματος" className="special" />
    </form>
  </div>
)

export default Form
