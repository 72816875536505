import React from 'react'
import PropTypes from 'prop-types'
import { FormattedMessage } from 'react-intl'
import Form from '../components/form'
import Layout from '../components/Layout'

const ContactPage = ({ pageContext: { locale } }) => (
  <Layout locale={locale}>
    <div className="Hero">
      <div className="HeroGroup">
        <h1>
          <FormattedMessage id="Contact" />
        </h1>
      </div>
    </div>
    <div className="intro">
      <h2>
        <FormattedMessage id="Contactus" />
      </h2>
      <p className="IntroServices">
        <FormattedMessage id="OurContactIntros" />
      </p>
    </div>
    <div className="Contactus">
      <div>
        <ul className="contactinfos">
          <li>        <a href="tel:2551041263">

            <FormattedMessage id="phone" />
            </a>
          </li>
          <li>

            <a href="tel:697398168">

            <FormattedMessage id="mobile" />
            </a>

          </li>
          <li>
          <a href="mailto:hotel.kima.samothraki@gmail.com" target="_top">

            <FormattedMessage id="email" />
            </a>

          </li>
          <li>
          <a href="https://www.google.gr/maps/place/Hotel+Kima/@40.4777137,25.4711283,17z/data=!3m1!4b1!4m7!3m6!1s0x14ae3b4ad4a82449:0xfbac325d83b1dfbf!5m1!1s2018-10-31!8m2!3d40.4777137!4d25.473317" target="_top">

            <FormattedMessage id="address" />
            </a>

          </li>
        </ul>
      </div>

      <Form title={<FormattedMessage id="Location" />} />
    </div>
  </Layout>
)

ContactPage.propTypes = {
  pageContext: PropTypes.shape({
    locale: PropTypes.string.isRequired
  }).isRequired
}

export default ContactPage
